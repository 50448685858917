import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

const Header = ({ sidebarCollapsed }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/', { state: { logoutMessage: 'You have successfully logged out.' } });
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${sidebarCollapsed ? '70px' : '240px'})`,
                ml: `${sidebarCollapsed ? '70px' : '240px'}`,
                backgroundColor: '#e31e25',
                transition: 'width 0.3s ease, margin-left 0.3s ease',
            }}
        >
            <Toolbar>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    Ace Care
                </Typography>
                <IconButton color="inherit" onClick={handleLogout} aria-label="logout" sx={{
                        color: 'white',
                        backgroundColor:'#a31406',
                        '&:hover': {
                            backgroundColor: '#000',
                        },
                    }}>
                    <LogoutIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
