// import React, { useState } from 'react';
// import { Box, CssBaseline, Toolbar } from '@mui/material';
// import SideBar from '../sideBar/SideBar';
// import Header from '../header/Header';
// import Footer from '../footer/Footer';

// const Layout = ({ children }) => {
//     const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

//     const handleSidebarCollapseChange = (collapsed) => {
//         setSidebarCollapsed(collapsed);
//     };

//     return (
//         <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#f4f6f8' }}>
//             <CssBaseline />
//             <Header sidebarCollapsed={sidebarCollapsed} />
//             <SideBar onCollapseChange={handleSidebarCollapseChange} />
//             <Box
//                 component="main"
//                 sx={{
//                     flexGrow: 1,
//                     marginLeft: sidebarCollapsed ? '70px' : '240px',
//                     transition: 'margin-left 0.3s ease',
//                     pt: 8, // Padding top to adjust for header height
//                     pb: 8, // Padding bottom to adjust for footer height
//                     backgroundColor: '#f4f6f8',
//                     minHeight: '100vh',
//                 }}
//             >
//                 <Toolbar /> {/* Space for fixed AppBar */}
//                 {children}
//             </Box>
//             <Footer sidebarCollapsed={sidebarCollapsed} />
//         </Box>
//     );
// };

// export default Layout;

// Layout.jsx
import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, useMediaQuery, Toolbar } from '@mui/material';
import SideBar from '../sideBar/SideBar';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Layout = ({ children }) => {
    // Determine initial sidebar state and handle screen resizing
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [sidebarCollapsed, setSidebarCollapsed] = useState(
        localStorage.getItem('sidebarCollapsed') === 'true'
    );

    useEffect(() => {
        // Sync sidebarCollapsed state with localStorage
        localStorage.setItem('sidebarCollapsed', sidebarCollapsed);
    }, [sidebarCollapsed]);

    // Handle sidebar toggle
    const handleSidebarCollapseChange = (collapsed) => {
        setSidebarCollapsed(collapsed);
    };

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column', backgroundColor: '#f4f6f8' }}>
            <CssBaseline />

            {/* Header */}
            <Header sidebarCollapsed={sidebarCollapsed} />

            {/* Main Content Wrapper */}
            <Box sx={{ display: 'flex', flexGrow: 1, mt: '5px' }}>
                {/* Sidebar */}
                <SideBar
                    onCollapseChange={handleSidebarCollapseChange}
                    isCollapsed={sidebarCollapsed}
                    isSmallScreen={isSmallScreen}
                />

                {/* Main Content Area */}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        padding: '5px',
                        marginLeft: sidebarCollapsed ? (isSmallScreen ? 0 : '0px') : (isSmallScreen ? 0 : '0px'),
                        transition: 'margin-left 0.3s ease',
                        backgroundColor: '#f4f6f8',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Toolbar /> {/* Space for fixed AppBar */}
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            padding: '5px',
                            overflowY: 'auto',
                            boxShadow: 1,
                            height: '100%', // Ensures full height
                            marginBottom: '64px', // Avoids overlap with footer
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            </Box>

            {/* Footer */}
            <Footer sidebarCollapsed={sidebarCollapsed} />
        </Box>
    );
};

export default Layout;




