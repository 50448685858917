import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import InventoryIcon from '@mui/icons-material/Inventory';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import './dashboard.css';
import Layout from '../../components/layout/Layout'; // Adjust the path as necessary

const Dashboard = () => {
    const location = useLocation();
    const [dashboardData, setDashboardData] = useState({
        total_users: 0,
        total_products: 0,
        total_orders: 0
    });

    const token = localStorage.getItem('token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    // Fetch dashboard data from API
    const fetchDashboardData = async () => {
        try {
            const response = await fetch('https://app.supersteelpowertools.com/api/admin/dashboard', requestOptions);
            const result = await response.json();
            if (response.ok && result.data.length > 0) {
                setDashboardData(result.data[0]);
            } else {
                toast.error('Failed to fetch dashboard data');
            }
        } catch (error) {
            toast.error('An error occurred while fetching the dashboard data');
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    useEffect(() => {
        if (location.state?.loginMessage) {
            toast.success(location.state.loginMessage);
            // Clear state to prevent showing the toast again on refresh
            window.history.replaceState({}, document.title);
        }
    }, [location.state]);

    return (
        <Layout>
            {/* <div className="dashboard-container" style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <div className="card-container">
                    <div className="card">
                        <Link to='/products' className="card-links">
                            <h1 className="card-title" >{dashboardData.total_products}</h1>
                            <h2 className="card-title">Products</h2>
                            <p className="card-content">Overview of your products.</p>
                        </Link>
                    </div>
                    <div className="card">
                        <Link to='/clients' className="card-links">
                            <h1 className="card-title">{dashboardData.total_users}</h1>
                            <h2 className="card-title">Clients</h2>
                            <p className="card-content">List of your clients.</p>
                        </Link>
                    </div>
                    <div className="card">
                        <Link to='/orders' className="card-links">
                            <h1 className="card-title">{dashboardData.total_orders}</h1>
                            <h2 className="card-title">Orders</h2>
                            <p className="card-content">Summary of recent orders.</p>
                        </Link>
                    </div>
                    
                </div>
            </div> */}
            <Box sx={{ padding: 4, backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
            <Grid container spacing={4}>
                {/* Products Card */}
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/products" style={{ textDecoration: 'none' }}>
                        <Card sx={{ backgroundColor: '#4caf50', color: 'white', height: '100%' }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h4">{dashboardData.total_products}</Typography>
                                    <InventoryIcon fontSize="large" />
                                </Box>
                                <Typography variant="h6">Products</Typography>
                                <Typography variant="body2">Overview of your products</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>

                {/* Clients Card */}
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/clients" style={{ textDecoration: 'none' }}>
                        <Card sx={{ backgroundColor: '#2196f3', color: 'white', height: '100%' }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h4">{dashboardData.total_users}</Typography>
                                    <PeopleIcon fontSize="large" />
                                </Box>
                                <Typography variant="h6">Clients</Typography>
                                <Typography variant="body2">List of your clients</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>

                {/* Orders Card */}
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/orders" style={{ textDecoration: 'none' }}>
                        <Card sx={{ backgroundColor: '#ff9800', color: 'white', height: '100%' }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h4">{dashboardData.total_orders}</Typography>
                                    <ShoppingCartIcon fontSize="large" />
                                </Box>
                                <Typography variant="h6">Orders</Typography>
                                <Typography variant="body2">Summary of recent orders</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
            <ToastContainer />
        </Box>
        </Layout>
    );
};

export default Dashboard;
