import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, TextField, Button, Select, MenuItem, CircularProgress, Container } from '@mui/material';
import Layout from '../../components/layout/Layout';

const GenerateInvoice = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [newOrderItems, setNewOrderItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [products, setProducts] = useState([]);
  const [productLoaded, setProductLoaded] = useState(false);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Token is missing.');

        const response = await fetch(`https://app.supersteelpowertools.com/api/admin/return_order/${id}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (!response.ok) throw new Error(`Error fetching data. Status: ${response.status}`);
        
        const data = await response.json();
        setOrderData(data.data);
        setOrderItems(
          data.data.order_items.map((item) => ({
            ...item,
            amount: calculateAmount(item.quantity, item.rate),
          }))
        );
        setInvoiceDate(new Date().toISOString().split('T')[0]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [id]);

  const fetchAllProducts = async () => {
    const token = localStorage.getItem('token');
    try {
      const formData = new FormData();
      formData.append('search', '');
      formData.append('limit', 1000);
      formData.append('offset', 0);

      const response = await fetch('https://app.supersteelpowertools.com/api/admin/get_product', {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) throw new Error(`Error fetching products. Status: ${response.status}`);
      
      const data = await response.json();
      setProducts(data.data);
      setProductLoaded(true);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const handleAddNewRow = () => {
    setNewOrderItems([
      ...newOrderItems,
      { product_code: '', product_name: '', quantity: 1, rate: 0, amount: 0 },
    ]);
  };

  const handleDeleteOrderItem = (index) => {
    const updatedOrderItems = orderItems.filter((_, i) => i !== index);
    setOrderItems(updatedOrderItems);
  };

  const handleDeleteNewRow = (index) => {
    const updatedNewItems = newOrderItems.filter((_, i) => i !== index);
    setNewOrderItems(updatedNewItems);
  };

  const handleProductSelect = (index, productCode, type) => {
    const selectedProduct = products.find((product) => product.product_code === productCode);
    if (selectedProduct) {
      const updatedItems = [...(type === 'order' ? orderItems : newOrderItems)];

      let rate;
      if (orderData.type.toLowerCase() === 'gst') {
        rate = selectedProduct.gst;
      } else {
        rate = selectedProduct.basic;
      }

      const amount = calculateAmount(updatedItems[index].quantity, rate);

      updatedItems[index] = {
        ...updatedItems[index],
        product_code: selectedProduct.product_code,
        product_name: selectedProduct.product_name,
        rate: rate,
        amount: amount,
      };

      if (type === 'order') {
        setOrderItems(updatedItems);
      } else {
        setNewOrderItems(updatedItems);
      }
    }
  };

  const handleNewItemChange = (index, field, value, type) => {
    const updatedItems = [...(type === 'order' ? orderItems : newOrderItems)];
    updatedItems[index] = { ...updatedItems[index], [field]: value };

    if (field === 'quantity' || field === 'rate') {
      updatedItems[index].amount = calculateAmount(
        updatedItems[index].quantity,
        updatedItems[index].rate
      );
    }

    if (type === 'order') {
      setOrderItems(updatedItems);
    } else {
      setNewOrderItems(updatedItems);
    }
  };

  const calculateAmount = (quantity, rate) => {
    return quantity * rate;
  };

  const calculateGrandTotal = () => {
    const total = [...orderItems, ...newOrderItems].reduce(
      (acc, item) => acc + parseFloat(item.amount || 0), 0
    );
    return total.toFixed(2);
  };

  const handleGenerateInvoice = async () => {
    const token = localStorage.getItem('token');

    const invoiceItems = [
      ...orderItems.map(item => ({
        product_code: item.product_code,
        product_name: item.product_name,
        quantity: item.quantity,
        rate: item.rate,
        total: item.amount,
        type: orderData?.type || "Gst",
      })),
      ...newOrderItems.map(item => ({
        product_code: item.product_code,
        product_name: item.product_name,
        quantity: item.quantity,
        rate: item.rate,
        total: item.amount,
        type: orderData?.type || "Gst",
      }))
    ];

    const invoiceData = {
      order_id: orderData?.order_id,
      user_id: orderData?.user_id,
      invoice_no: orderData?.order_invoice_id,
      invoice_date: invoiceDate,
      amount: calculateGrandTotal(),
      type: orderData?.type || "Gst",
      invoice_items: invoiceItems
    };

    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify([invoiceData]));

      const response = await fetch('https://abc.com/api/admin/add_invoice', {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) throw new Error(`Error sending invoice. Status: ${response.status}`);
      
      const result = await response.json();
      console.log("Invoice generated successfully:", result);
    } catch (error) {
      console.error("Error generating invoice:", error.message);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Layout>
      <Container>
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>Generate Invoice</Typography>

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <TextField label="Order ID" value={orderData?.order_id || ''} fullWidth InputProps={{ readOnly: true }} />
            <TextField label="Invoice ID" value={orderData?.order_invoice_id || ''} fullWidth InputProps={{ readOnly: true }} />
            <TextField label="Invoice Date" value={invoiceDate} fullWidth InputProps={{ readOnly: true }} />
            <TextField label="Order Type" value={orderData?.type || ''} fullWidth InputProps={{ readOnly: true }} />
          </Box>

          <Typography variant="h6" sx={{ mb: 2 }}>Order Items</Typography>
          {orderItems.map((item, index) => (
            <Box key={index} display="flex" gap={2} alignItems="center" mb={2}>
              <Select
                value={item.product_code}
                onChange={(e) => handleProductSelect(index, e.target.value, 'order')}
                fullWidth
                displayEmpty
                sx={{ minWidth: 200 }}
              >
                <MenuItem value="">Select a product</MenuItem>
                {products.map((product) => (
                  <MenuItem key={product.product_code} value={product.product_code}>
                    {product.product_name}
                  </MenuItem>
                ))}
              </Select>
              <TextField label="Rate" value={item.rate} fullWidth InputProps={{ readOnly: true }} />
              <TextField label="Quantity" value={item.quantity} onChange={(e) => handleNewItemChange(index, 'quantity', e.target.value, 'order')} fullWidth />
              <TextField label="Product Code" value={item.product_code} fullWidth InputProps={{ readOnly: true }} />
              <TextField label="Amount" value={item.amount} fullWidth InputProps={{ readOnly: true }} />
              <Button variant="outlined" color="error" onClick={() => handleDeleteOrderItem(index)}>Delete</Button>
            </Box>
          ))}

          {/* Show Add Product button and New Order Items section conditionally */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="contained" color="primary" onClick={handleAddNewRow} sx={{ mb: 2 }}>
              Add Product
            </Button>
          </Box>
          
          {/* Show "New Order Items" section only if there are new items */}
          {newOrderItems.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>New Order Items</Typography>
              {newOrderItems.map((item, index) => (
                <Box key={`new-${index}`} display="flex" gap={2} alignItems="center" mb={2}>
                  <Select
                    value={item.product_code}
                    onChange={(e) => handleProductSelect(index, e.target.value, 'new')}
                    fullWidth
                    displayEmpty
                    sx={{ minWidth: 200 }}
                  >
                    <MenuItem value="">Select a product</MenuItem>
                    {products.map((product) => (
                      <MenuItem key={product.product_code} value={product.product_code}>
                        {product.product_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField label="Rate" value={item.rate} fullWidth InputProps={{ readOnly: true }} />
                  <TextField label="Quantity" value={item.quantity} onChange={(e) => handleNewItemChange(index, 'quantity', e.target.value, 'new')} fullWidth />
                  <TextField label="Product Code" value={item.product_code} fullWidth InputProps={{ readOnly: true }} />
                  <TextField label="Amount" value={item.amount} fullWidth InputProps={{ readOnly: true }} />
                  <Button variant="outlined" color="error" onClick={() => handleDeleteNewRow(index)}>Delete</Button>
                </Box>
              ))}
            </>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, gap: 2 }}>
            <Typography variant="h6">Grand Total</Typography>
            <TextField value={calculateGrandTotal()} fullWidth InputProps={{ readOnly: true }} />
          </Box>

          {/* Generate Invoice Button on the Right */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="contained" color="success" onClick={handleGenerateInvoice}>
              Generate Invoice
            </Button>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default GenerateInvoice;
