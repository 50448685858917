import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, InputAdornment, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Layout from '../../components/layout/Layout';
import './clients.css';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newClientsCount, setNewClientsCount] = useState(0);
  const [pageSize, setPageSize] = useState(10); // Number of rows per page
  const [open, setOpen] = useState(false); // Controls dialog visibility
  const [selectedClient, setSelectedClient] = useState(null); // Stores the selected client details
  const [role, setRole] = useState('normal'); // State to control role selection
  const [successMessage, setSuccessMessage] = useState(''); // Flash message

  const fetchClients = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    if (!token) {
      setError("Token is missing. Please login again.");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = { method: 'GET', headers: myHeaders };

    try {
      const response = await fetch('https://app.supersteelpowertools.com/api/admin/view_user', requestOptions);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      
      const result = await response.json();
      setClients(result.data);
      setFilteredClients(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    const filteredData = searchQuery
      ? clients.filter(client =>
          ['name', 'email', 'mobile', 'city', 'state', 'role'].some(field =>
            client[field]?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
      : clients;
    setFilteredClients(filteredData);
  }, [searchQuery, clients]);

  const handleOpenDialog = (client) => {
    setSelectedClient(client);
    setRole('normal'); // Reset role to default
    setOpen(true); // Open dialog
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedClient(null);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleAcceptClient = async () => {
    if (!selectedClient) return;

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('type', role);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(`https://app.supersteelpowertools.com/api/admin/make_verify/${selectedClient.id}`, requestOptions);
      const result = await response.json();
      
      if (response.ok && result.message) {
        setSuccessMessage(`User verified successfully as ${role}`); // Flash message on success
        fetchClients(); // Refresh the client list
      } else {
        throw new Error(result.message || 'Failed to verify client');
      }

      handleCloseDialog(); // Close dialog after success
    } catch (error) {
      setError(error.message);
    }
  };

  const syncClients = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError("Token is missing. Please login again.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = { method: 'GET', headers: myHeaders };

    try {
      const response = await fetch(`https://app.supersteelpowertools.com/api/fetch_users`, requestOptions);
      if (response.ok){
        setSuccessMessage(`Imported Successfully`); 
      } else{
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      const newClients = result.new_data || [];
      setClients((prevClients) => [...prevClients, ...newClients]);
      setFilteredClients((prevClients) => [...prevClients, ...newClients]);
      setNewClientsCount(newClients.length);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleToggleVerification = async (client) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError("Token is missing. Please login again.");
      return;
    }
  
    const newType = client.verified === '1' ? 'normal' : role; // Toggle between 'normal' and selected role
  
    const formData = new FormData();
    formData.append('type', newType);
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    };
  
    try {
      const response = await fetch(`https://app.supersteelpowertools.com/api/admin/make_verify/${client.id}`, requestOptions);
      const result = await response.json();
  
      if (response.ok && result.message) {
        setSuccessMessage(result.message); // Display the response message as a flash message
        fetchClients(); // Refresh the client list
      } else {
        throw new Error(result.message || 'Failed to toggle verification');
      }
    } catch (error) {
      setError(error.message);
    }
  };
  
  const columns = [
    {
      field: 'name',
      headerName: 'Client Name',
      flex: 1, align: 'center',
      headerAlign: 'center',
      cellClassName: 'wrap-cell',  // Custom cell class for wrapping
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1, align: 'center',
      headerAlign: 'center',
      cellClassName: 'wrap-cell',  // Custom cell class for wrapping
      renderCell: (params) => params.value || 'N/A'
    },
    { field: 'mobile', headerName: 'Mobile', flex: 1, align: 'center', headerAlign: 'center', filterable: true, type: 'number' },
    { field: 'city', headerName: 'City', flex: 1, headerAlign: 'center' },
     { field: 'role', headerName: 'Role', flex: 1, align: 'center' },
    {
      field: 'verified',
      headerName: 'Verified',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        params.value === '1' ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#00000038',
              border: '0px',
              color: '#fff',
            }}
            onClick={() => handleToggleVerification(params.row)}
          >
            Unverify
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpenDialog(params.row)}
            sx={{
              backgroundColor: '#e31e25',
              border: '0px',
              color: '#fff',
            }}
          >
            Verify
          </Button>
        ),
    }

  ];

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Button
            onClick={syncClients}
            variant="contained"
            startIcon={<SyncIcon />}
            sx={{ backgroundColor: '#e74c3c', color: 'white' }}
          >
            Sync Clients
          </Button>
          {newClientsCount > 0 && (
            <Typography sx={{ ml: 2, color: '#28a745', fontWeight: 'bold' }}>
              {newClientsCount} new clients added
            </Typography>
          )}
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { height: 40 },
            }}
            sx={{ maxWidth: 300, backgroundColor: 'white' }}
          />
        </Box>

        {/* <Box sx={{ mb: 3 }}>
          <Typography variant="body2" sx={{ mr: 2 }}>Rows per page:</Typography>
          <Select value={pageSize} onChange={(e) => setPageSize(e.target.value)} sx={{ minWidth: 80 }}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box> */}

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" sx={{ textAlign: 'center', mt: 3 }}>
            {error}
          </Typography>
        ) : (
          <Box sx={{ height: { xs: '60vh', sm: '70vh', md: '65vh' }, width: '100%' }}>
            <DataGridPro
              rows={filteredClients.map((client, index) => ({ ...client, id: client.id || index }))}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              checkboxSelection
              components={{ Toolbar: true }}
              headerFilters
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#e31e25',
                  color: '#000',
                },
                '& .wrap-cell': {
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                },
                '& .MuiDataGrid-cell': {
                  textAlign: 'center',
                },
              }}
            />
          </Box>
        )}

        <Snackbar
          open={!!successMessage}
          autoHideDuration={3000}
          onClose={() => setSuccessMessage('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>

        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Verify Client</DialogTitle>
          <DialogContent>
            <TextField
              label="Client Name"
              value={selectedClient?.name || ''}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
            <Select
              label="Role"
              value={role}
              onChange={handleRoleChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="normal">Normal</MenuItem>
              <MenuItem value="outstation">Outstation</MenuItem>
              <MenuItem value="special">Special</MenuItem>
              <MenuItem value="zeroprice">Zeroprice</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAcceptClient} color="primary">
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default Clients;



// import React, { useState, useEffect } from 'react';
// import { Box, Button, TextField, Typography, InputAdornment, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, Snackbar, Alert } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import SyncIcon from '@mui/icons-material/Sync';
// import { DataGridPro } from '@mui/x-data-grid-pro';
// import Layout from '../../components/layout/Layout';
// import './clients.css';

// const Clients = () => {
//   const [clients, setClients] = useState([]);
//   const [filteredClients, setFilteredClients] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [newClientsCount, setNewClientsCount] = useState(0);
//   const [pageSize, setPageSize] = useState(10);
//   const [open, setOpen] = useState(false);
//   const [selectedClient, setSelectedClient] = useState(null);
//   const [role, setRole] = useState('normal');
//   const [successMessage, setSuccessMessage] = useState('');

//   const fetchClients = async () => {
//     setLoading(true);
//     setError(null);

//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError("Token is missing. Please login again.");
//       setLoading(false);
//       return;
//     }

//     const myHeaders = new Headers();
//     myHeaders.append('Authorization', `Bearer ${token}`);

//     const requestOptions = { method: 'GET', headers: myHeaders };

//     try {
//       const response = await fetch('https://app.supersteelpowertools.com/api/admin/view_user', requestOptions);
//       if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      
//       const result = await response.json();
//       setClients(result.data);
//       setFilteredClients(result.data);
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchClients();
//   }, []);

//   useEffect(() => {
//     const filteredData = searchQuery
//       ? clients.filter(client =>
//           ['name', 'email', 'mobile', 'city', 'state', 'role'].some(field =>
//             client[field]?.toLowerCase().includes(searchQuery.toLowerCase())
//           )
//         )
//       : clients;
//     setFilteredClients(filteredData);
//   }, [searchQuery, clients]);

//   const handleOpenDialog = (client) => {
//     setSelectedClient(client);
//     setRole('normal');
//     setOpen(true);
//   };

//   const handleCloseDialog = () => {
//     setOpen(false);
//     setSelectedClient(null);
//   };

//   const handleRoleChange = (e) => {
//     setRole(e.target.value);
//   };

//   const handleVerifyClient = async () => {
//     if (!selectedClient) return;

//     const token = localStorage.getItem('token');
//     const formData = new FormData();
//     formData.append('type', role);

//     const requestOptions = {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//       },
//       body: formData,
//     };

//     try {
//       const response = await fetch(`https://app.supersteelpowertools.com/api/admin/make_verify/${selectedClient.id}`, requestOptions);
//       const result = await response.json();
      
//       if (response.ok && result.message) {
//         setSuccessMessage(`User verified successfully as ${role}`);
//         fetchClients();
//       } else {
//         throw new Error(result.message || 'Failed to verify client');
//       }

//       handleCloseDialog();
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const handleUnverifyClient = async (client) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError("Token is missing. Please login again.");
//       return;
//     }

//     const requestOptions = {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//       },
//     };

//     try {
//       const response = await fetch(`https://app.supersteelpowertools.com/api/admin/unv/${client.id}`, requestOptions);
//       const result = await response.json();

//       if (response.ok && result.message) {
//         setSuccessMessage(result.message);
//         fetchClients();
//       } else {
//         throw new Error(result.message || 'Failed to unverify client');
//       }
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const syncClients = async () => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError("Token is missing. Please login again.");
//       return;
//     }

//     const myHeaders = new Headers();
//     myHeaders.append('Authorization', `Bearer ${token}`);

//     const requestOptions = { method: 'GET', headers: myHeaders };

//     try {
//       const response = await fetch(`https://app.supersteelpowertools.com/api/fetch_users`, requestOptions);
//       if (response.ok){
//         setSuccessMessage(`Imported Successfully`); 
//       } else{
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
//       const result = await response.json();
//       const newClients = result.new_data || [];
//       setClients((prevClients) => [...prevClients, ...newClients]);
//       setFilteredClients((prevClients) => [...prevClients, ...newClients]);
//       setNewClientsCount(newClients.length);
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const columns = [
//     {
//       field: 'name',
//       headerName: 'Client Name',
//       flex: 1, align: 'center',
//       headerAlign: 'center',
//       cellClassName: 'wrap-cell',
//     },
//     {
//       field: 'email',
//       headerName: 'Email',
//       flex: 1, align: 'center',
//       headerAlign: 'center',
//       cellClassName: 'wrap-cell',
//       renderCell: (params) => params.value || 'N/A'
//     },
//     { field: 'mobile', headerName: 'Mobile', flex: 1, align: 'center', headerAlign: 'center', filterable: true, type: 'number' },
//     { field: 'city', headerName: 'City', flex: 1, headerAlign: 'center' },
//     { field: 'role', headerName: 'Role', flex: 1, align: 'center' },
//     {
//       field: 'verified',
//       headerName: 'Verified',
//       flex: 1,
//       align: 'center',
//       headerAlign: 'center',
//       renderCell: (params) =>
//         params.value === '1' ? (
//           <Button
//             variant="contained"
//             sx={{
//               backgroundColor: '#000',
//               border: '0px',
//               color: '#fff',
//             }}
//             onClick={() => handleUnverifyClient(params.row)}
//           >
//             Unverify
//           </Button>
//         ) : (
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={() => handleOpenDialog(params.row)}
//             sx={{
//               backgroundColor: '#e31e25',
//               border: '0px',
//               color: '#fff',
//             }}
//           >
//             Verify
//           </Button>
//         ),
//     }
//   ];

//   return (
//     <Layout>
//       <Box sx={{ p: 3 }}>
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
//           <Button
//             onClick={syncClients}
//             variant="contained"
//             startIcon={<SyncIcon />}
//             sx={{ backgroundColor: '#e74c3c', color: 'white' }}
//           >
//             Sync Clients
//           </Button>
//           {newClientsCount > 0 && (
//             <Typography sx={{ ml: 2, color: '#28a745', fontWeight: 'bold' }}>
//               {newClientsCount} new clients added
//             </Typography>
//           )}
//           <TextField
//             variant="outlined"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <SearchIcon />
//                 </InputAdornment>
//               ),
//               sx: { height: 40 },
//             }}
//             sx={{ maxWidth: 300, backgroundColor: 'white' }}
//           />
//         </Box>

//         {loading ? (
//           <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
//             <CircularProgress />
//           </Box>
//         ) : error ? (
//           <Typography color="error" sx={{ textAlign: 'center', mt: 3 }}>
//             {error}
//           </Typography>
//         ) : (
//           <Box sx={{ height: { xs: '60vh', sm: '70vh', md: '65vh' }, width: '100%' }}>
//             <DataGridPro
//               rows={filteredClients.map((client, index) => ({ ...client, id: client.id || index }))}
//               columns={columns}
//               pageSize={pageSize}
//               onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//               rowsPerPageOptions={[10, 25, 50, 100]}
//               disableSelectionOnClick
//               checkboxSelection
//               components={{ Toolbar: true }}
//               headerFilters
//               sx={{
//                 '& .MuiDataGrid-columnHeaders': {
//                   backgroundColor: '#e31e25',
//                   color: '#000',
//                 },
//                 '& .wrap-cell': {
//                   whiteSpace: 'normal',
//                   wordBreak: 'break-word',
//                 },
//                 '& .MuiDataGrid-cell': {
//                   textAlign: 'center',
//                 },
//               }}
//             />
//           </Box>
//         )}

//         <Snackbar
//           open={!!successMessage}
//           autoHideDuration={3000}
//           onClose={() => setSuccessMessage('')}
//           anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//         >
//           <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
//             {successMessage}
//           </Alert>
//         </Snackbar>

//         <Dialog open={open} onClose={handleCloseDialog}>
//           <DialogTitle>Verify Client</DialogTitle>
//           <DialogContent>
//             <TextField
//               label="Client Name"
//               value={selectedClient?.name || ''}
//               fullWidth
//               margin="normal"
//               InputProps={{ readOnly: true }}
//             />
//             <Select
//               label="Role"
//               value={role}
//               onChange={handleRoleChange}
//               fullWidth
//               margin="normal"
//             >
//               <MenuItem value="normal">Normal</MenuItem>
//               <MenuItem value="outstation">Outstation</MenuItem>
//               <MenuItem value="special">Special</MenuItem>
//               <MenuItem value="zeroprice">Zeroprice</MenuItem>
//             </Select>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseDialog} color="primary">
//               Cancel
//             </Button>
//             <Button onClick={handleVerifyClient} color="primary">
//               Accept
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Box>
//     </Layout>
//   );
// };

// export default Clients;
