import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = ({ sidebarCollapsed }) => {
    return (
        <Box
            component="footer"
            sx={{
                width: `calc(100% - ${sidebarCollapsed ? '70px' : '240px'})`,
                ml: `${sidebarCollapsed ? '70px' : '240px'}`,
                backgroundColor: '#e31e25',
                color: 'white',
                textAlign: 'center',
                py: 1,
                mt: 'auto', // Ensures footer is at the bottom
                transition: 'width 0.3s ease, margin-left 0.3s ease',
                position: 'fixed', // Keep the footer fixed at the bottom
                bottom: 0, // Stick to the bottom of the viewport
            }}
        >
            <Typography variant="body2">
                Ace Commercial Equipments © 2024, All rights reserved. Proudly Powered By Dot Com Solutions
            </Typography>
        </Box>
    );
};

export default Footer;
