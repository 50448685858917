import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const SideBar = ({ onCollapseChange }) => {
    // Initialize isCollapsed state based on localStorage
    const [isCollapsed, setIsCollapsed] = useState(
        localStorage.getItem('sidebarCollapsed') === 'true'
    );

    // Update localStorage whenever isCollapsed changes
    useEffect(() => {
        localStorage.setItem('sidebarCollapsed', isCollapsed);
    }, [isCollapsed]);

    const toggleSidebar = () => {
        const newCollapseState = !isCollapsed;
        setIsCollapsed(newCollapseState);
        onCollapseChange(newCollapseState); // Notify parent about the sidebar state change
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: isCollapsed ? 70 : 240,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: isCollapsed ? 70 : 240,
                    backgroundColor: '#000000', // Sidebar background color set to black
                    color: 'white', // Font color set to white
                    transition: 'width 0.3s ease',
                    overflowX: 'hidden',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: isCollapsed ? 'center' : 'flex-end',
                    alignItems: 'center',
                    padding: '0 8px',
                }}
            >
                <IconButton
                    color="inherit"
                    onClick={toggleSidebar}
                    sx={{
                        '&:hover': {
                            color: '#e31e25', // Hover color set to red for the toggle icon
                        },
                    }}
                >
                    {isCollapsed ? <MenuIcon /> : <CloseIcon />}
                </IconButton>
            </Box>
            <List>
                {/* Dashboard */}
                <ListItem
                    button
                    component={Link}
                    to="/dashboard"
                    sx={{
                        color: 'white', // Text color
                        '&:hover': {
                            backgroundColor: '#e31e25', // Hover color set to red
                        },
                    }}
                >
                    <ListItemIcon sx={{ color: 'white' }}>
                        <DashboardIcon />
                    </ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Dashboard" />}
                </ListItem>

                {/* Products */}
                <ListItem
                    button
                    component={Link}
                    to="/products"
                    sx={{
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#e31e25',
                        },
                    }}
                >
                    <ListItemIcon sx={{ color: 'white' }}>
                        <InventoryIcon />
                    </ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Products" />}
                </ListItem>

                {/* Orders */}
                <ListItem
                    button
                    component={Link}
                    to="/orders"
                    sx={{
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#e31e25',
                        },
                    }}
                >
                    <ListItemIcon sx={{ color: 'white' }}>
                        <ShoppingCartIcon />
                    </ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Orders" />}
                </ListItem>

                {/* Clients */}
                <ListItem
                    button
                    component={Link}
                    to="/clients"
                    sx={{
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#e31e25',
                        },
                    }}
                >
                    <ListItemIcon sx={{ color: 'white' }}>
                        <PeopleIcon />
                    </ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Clients" />}
                </ListItem>
            </List>
        </Drawer>
    );
};

export default SideBar;
